export default function TelegramLogo({ className }) {
  return (
    <svg
      height="64"
      width="64"
      viewBox="155 0 512 512"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M295.5,221l-69.8,42c-1.2,0.7-1.7,2.1-1.3,3.4l9.1,32.6c0.3,0.9,1.6,0.8,1.7-0.2l2-22.5
          c0.1-1.7,0.9-3.3,2.2-4.4l57-49.7C297,221.6,296.2,220.5,295.5,221z"
          fill="#FFFFFF"
        />
        <path
          d="M322.3,154.8H189.8c-19.3,0-35,15.7-35,35v132.5c0,19.3,15.7,35,35,35h132.5c19.3,0,35-15.7,35-35V189.8
          C357.3,170.5,341.5,154.8,322.3,154.8z M328.1,199l-25.6,116.3c-0.9,3.9-5.5,5.5-8.6,3l-30-23.8c-2.2-1.8-5.5-1.5-7.4,0.7
          L241.1,313c-2.7,3.2-7.9,2.1-9.1-1.8l-13.6-41.9c-0.6-1.7-2-3.1-3.7-3.5l-26.9-7.1c-4.9-1.3-5.4-8.1-0.7-10.1l133.7-55.7
          C324.8,191.3,329,194.8,328.1,199z"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  );
}
