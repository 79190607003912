import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectTime({ onChange }) {
  const [selectedValue, setSelectedValue] = useState('day');

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    let timeRange;

    switch (selectedValue) {
      case 'day':
        timeRange = 86400;
        break;
      case 'week':
        timeRange = 604800;
        break;
      case 'month':
        timeRange = 2592000;
        break;
      case 'year':
        timeRange = 31536000;
        break;
      default:
        timeRange = 86400;
    }

    setSelectedValue(selectedValue);
    onChange(timeRange);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Time Range</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          label="Time Range"
          onChange={handleChange}
        >
          <MenuItem value="day">Day</MenuItem>
          <MenuItem value="week">Week</MenuItem>
          <MenuItem value="month">Month</MenuItem>
          <MenuItem value="year">Year</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}