import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));

// ANALYTIC PAGE
const AnalyticDefault = Loadable(lazy(() => import("app/views/analytics/T2Analytic")));

// HOME PAGE
const Home = Loadable(lazy(() => import("app/views/home/Home")));
const DashboardPage = Loadable(lazy(() => import("app/views/home/DashboardPage")));
// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("app/views/sessions/JwtRegister")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));
const EmailFinder = Loadable(lazy(() => import("app/views/emailpass/EmailFinder")));
const PrivateChannel = Loadable(lazy(() => import("app/views/privatechannel/PrivateChannelList")));



const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      // home route
      { path: "/home", element: <Home />, auth: authRoles.admin },
      // dashboard route
     // { path: "/dashboard/default", element: <Analytics />, auth: authRoles.admin },
      // analytic route
      { path: "/analytic/default", element: <AnalyticDefault />, auth: authRoles.admin },
      { path: "/dashboard/:channelId", element: <DashboardPage />, auth: authRoles.admin },
      //{ path: "/find/email", element: <EmailFinder />, auth: authRoles.admin },
     // { path: "/find/privatechannel", element: <PrivateChannel />, auth: authRoles.admin }


    ]
  },
  // session pages route
  {
    name: "Session/Auth",
    icon: "security",
    children: [
      { name: "Sign in", iconText: "SI", path: "/session/signin", element: <JwtLogin /> },
      { name: "Sign up", iconText: "SU", path: "/session/signup", element: <JwtRegister /> },
      {
        name: "Forgot Password",
        iconText: "FP",
        path: "/session/forgot-password",
        element: <ForgotPassword />
      },
      { name: "Error", iconText: "404", path: "/session/404", element: <NotFound /> }
    ]
  },
  // redirect to dashboard
  { path: "/", element: <Navigate to="home" /> },
  // fallback for unmatched routes
  { path: "*", element: <Navigate to="home" /> }
];

export default routes;
