import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import SelectTime from "app/views/material-kit/select/SelectTime"

function LinkAnalysis() {
    const [filters, setFilters] = useState({ user: '', date: '' });
    const [domains, setDomains] = useState([]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const applyFilters = async () => {
        try {
            const response = await axios.post('https://sociall.live/api/analytic/link', {
                filters
            });
            setDomains(response.data.domains);
        } catch (error) {
            console.error('Error applying filters:', error);
        }
    };

    return (
        <div style={{ padding: 20 }}>
            <Box sx={{ marginBottom: 5, display: "flex", alignItems: "center", gap: 2 }}>
                <TextField label="User" name="user" value={filters.user} onChange={handleFilterChange} sx={{ flex: 1 }} />
                <TextField label="Date" name="date" type="date" value={filters.date} onChange={handleFilterChange} sx={{ flex: 1 }} InputLabelProps={{ shrink: true }} />
                <SelectTime />
                <Button variant="contained" color="primary" onClick={applyFilters}>
                    Apply Filters
                </Button>
            </Box>
            <h2>Link Domains Analysis</h2>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={domains}>
                    <XAxis dataKey="domain" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default LinkAnalysis;